import { css } from '@emotion/core';

export const themeVars = {
  root: {
    // Colours
    primary: '#14255E',
    secondary: '#009FE3',
    tertiary: '#9FAABC',
    dark: '#000000',
    darkAlt: '#171A27',
    light: '#FFFFFF',

    // Fonts
    headerFontFamily: '"Work Sans", Helvetica, Arial, sans-serif;',
    bodyFontFamily: '"Work Sans", Helvetica, Arial sans-serif;',

    // Padding
    padTopSm: '3rem',
    padTopMd: '4rem',
    padTopLg: '6rem',

    padBottomSm: '3rem',
    padBottomMd: '4rem',
    padBottomLg: '6rem',
  },
};

// Uses !important for grid system padding with fluid
export const cssNoPadding = css`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

export const cssExtraMargin = css`
  margin-left: 5vh !important;
  margin-right: 5vh !important;
`;


export default themeVars;
