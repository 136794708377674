// Pollyfill for the intersection observer, adds functionality for IE11 and older browsers
import 'intersection-observer';

import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { ScreenClassProvider } from 'react-grid-system';

import './src/global.css';

// Theme
import theme from './src/config/mui-theme';

// Components
import App from './src/components/App';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts

  return (
    <ScreenClassProvider>
      <ThemeProvider theme={theme}>
        <App>{element}</App>
      </ThemeProvider>
    </ScreenClassProvider>
  );
};
