import { createMuiTheme } from '@material-ui/core/styles';

// Vars
import { themeVars } from '../config/styles';
import { bp } from '../config/breakpoints';

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10 /* 62.5% of 16px = 10px */,
    fontFamily: ['Work Sans', '-apple-system', 'BlinkMacSystemFont', 'Arial', 'sans-serif'],
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: themeVars.root.primary,
    },
    secondary: {
      main: themeVars.root.secondary,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        [`@media (max-width: ${bp.mx_md})`]: {
          width: '100%',
        },
      },
    },
  },
});

export default theme;
