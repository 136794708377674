// Breakpoints
export const bp = {
  // max-width breakpoints
  mx_xl: '1641px',
  mx_lg: '1259px',
  mx_md: '767px',
  mx_xs: '575px',

  // min-width breakpoints
  mn_xl: '1640px',
  mn_lg: '1260px',
  mn_md: '768px',
  mn_xs: '300px',
};

export default bp;
