import React from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/core';

// Vars
import { themeVars } from '../config/styles';
// Components

const App = ({ children }) => {
  return (
    <React.Fragment>
      <Global
        styles={css`
          &.entry-content {
            a {
              color: ${themeVars.root.primary};
              text-decoration: underline;
              transition: 250ms ease-in-out all;
            }
          }

          h1 {
            color: ${themeVars.root.primary};
            font-size: 7rem;
            margin-bottom: 50px;
          }

          h2 {
            color: ${themeVars.root.primary};
            font-size: 4rem;
            margin-bottom: 50px;
          }

          h3 {
            color: ${themeVars.root.primary};
            font-size: 3rem;
            margin-bottom: 30px;
          }

          .content-modules {
            section {
              padding: 10rem 0;
              background-color: #e5ecef;

              &:nth-of-type(even) {
                background-color: #ffffff;
              }
            }
          }
        `}
      />

      <div className="app">{children}</div>
    </React.Fragment>
  );
};

App.propTypes = {
  children: PropTypes.node.isRequired,
};

export default App;
