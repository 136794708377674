/* eslint-disable no-alert */
import '@babel/polyfill';
import { setConfiguration } from 'react-grid-system';

import wrapWithProvider from './wrap-with-provider';

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    'This application has been updated. Reload to display the latest version?'
  );

  if (answer === true) {
    window.location.reload();
  }
};

// Set up the grid sizes - https://github.com/JSxMachina/react-grid-system
setConfiguration({
  containerWidths: [540, 738, 1230, 1570],
  breakpoints: [576, 768, 1260, 1600],
});
// eslint-disable-next-line import/prefer-default-export
export const onClientEntry = () => {
  // Without this function body the import will not be picked up.
};

export const wrapRootElement = wrapWithProvider;
