// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-archive-news-js": () => import("./../../../src/templates/archive-news.js" /* webpackChunkName: "component---src-templates-archive-news-js" */),
  "component---src-templates-archive-projects-js": () => import("./../../../src/templates/archive-projects.js" /* webpackChunkName: "component---src-templates-archive-projects-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

